import { useEffect } from 'react';

import { useLocation } from '@remix-run/react';

import { useUser } from '~/providers';

import { analytics } from '~/services/segment.client';

// This hook takes care of
// - identifying the user in segment when the user logs in
// - resetting the segment user when the user logs out
// - resetting all segment users to this day so all users start with a clean slate
// - tracking page views

type Props = {
  shouldLogout?: boolean;
};

export const useSegmentInit = ({ shouldLogout = false }: Props = {}) => {
  const currentLocation = useLocation();
  const { user, account } = useUser();

  // This effect takes care of identifying the user in segment when the user logs in
  useEffect(() => {
    analytics.user().then((segmentUser) => {
      const segmentUserId = segmentUser.id();

      if (!segmentUserId && user && account) {
        analytics.identify(user.id.toString().padStart(8, '0'), {
          companyId: account.id.toString().padStart(8, '0'),
          freemium: account.features.free_access?.amount === 1,
          internal: user.email.includes('@raffle.ai'),
        });
      }
    });
  }, [user, account]);

  // This effect takes care of resetting the segment user when the user logs out
  useEffect(() => {
    analytics.user().then((segmentUser) => {
      const segmentUserId = segmentUser.id();

      if (segmentUserId && shouldLogout) {
        segmentUser.reset();
      }
    });
  }, [shouldLogout]);

  // This effect takes care of resdetting all segment users to this day so all users start with a clean slate
  useEffect(() => {
    analytics.user().then((segmentUser) => {
      const segmentUserId = segmentUser.id();
      if (
        (segmentUserId && segmentUserId.length < 8) ||
        segmentUserId?.includes('-')
      ) {
        analytics.reset();
      }
    });
  }, []);

  // This effect takes care of tracking page views
  useEffect(() => {
    analytics.user().then(() => {
      analytics.page();
    });
  }, [currentLocation.pathname]);
};
